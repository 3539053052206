
import React, { useState } from "react"

import * as styles from "./style.module.css"

import imgmenuclose from "../../images/menu-close.png"


const PreviewpaneComponent = ({children, visible, title, urlsrc, handleClose}) => {
	const [overrideclose, setOverrideclose] = useState(false);

	function closepane(e)
	{
		if (e) {
			e.preventDefault()
		}
		if (typeof handleClose !== "undefined") {
			handleClose();
		} else {
			setOverrideclose(true);
		}
	}

	const cururl = typeof urlsrc !== "undefined"?urlsrc:"";
	return <>{(visible && overrideclose === false)&&<div className={styles.paneholder}>
		<div tabIndex='0' className={styles.shadow}>&nbsp;</div>
		<div className={styles.content}>
			<div className={styles.contentheader}>
				<h2>{title}</h2>
				<button title={"Close"} className={"iconbutton iconbutton-large"} onClick={closepane}>
					<img src={imgmenuclose} alt={"Close"} />
				</button>
			</div>
			<div className={styles.contentbody}>
				{cururl.length > 0 ?
					<iframe className={styles.contentframe} src={cururl} />
				:<>
					{children?children:<></>}
				</>}
			</div>
		</div>
	</div>}</>
}


export default PreviewpaneComponent;
